import { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdClose } from "react-icons/md";

export const OwnerModal = ({
  NewPerson,
  setNewPerson,
  HandelAddOwner,
  showModal,
  HandelCloseOwnerModal,
  index,
}) => {
  const handleOverlayClick = (e) => {
    if (e.target.id === "modal-overlay") {
      HandelCloseOwnerModal();
    }
  };

  return (
    <>
      {showModal && (
        <>
          {/* Modal Overlay */}
          <div
            id="modal-overlay"
            className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50"
            onClick={handleOverlayClick}
          >
            {/* Modal Content */}
            <div className="w-[500px] bg-white rounded-lg shadow-lg z-999">
              {/* Header */}
              <div className="bg-white-100 p-4 rounded-t-lg border-b">
                <h2 className="text-xl font-semibold text-gray-700">Add Owner - {index}</h2> 
              </div>

              {/* Body */}
              <div className="p-6">
                <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 gap-4">
                  {/* First Name Input */}
                  <input
                    type="text"
                    value={NewPerson.Firstname}
                    onChange={(e) =>
                      setNewPerson({ ...NewPerson, Firstname: e.target.value })
                    }
                    placeholder="First Name"
                    className="col-span-1 text-gray-900 bg-white rounded-md border border-gray-300 px-4 py-2"
                  />
                  {/* Middle Name Input */}
                  <input
                    type="text"
                    value={NewPerson.MiddleName}
                    onChange={(e) =>
                      setNewPerson({
                        ...NewPerson,
                        MiddleName: e.target.value,
                      })
                    }
                    placeholder="Middle Name"
                    className="col-span-1 text-gray-900 bg-white rounded-md border border-gray-300 px-4 py-2"
                  />
                  {/* Last Name Input */}
                  <input
                    type="text"
                    value={NewPerson.LastName}
                    onChange={(e) =>
                      setNewPerson({ ...NewPerson, LastName: e.target.value })
                    }
                    placeholder="Last Name"
                    className="col-span-1 text-gray-900 bg-white rounded-md border border-gray-300 px-4 py-2"
                  />
                  {/* Phone Input */}
                  <input
                    type="number"
                    value={NewPerson.Phone}
                    onChange={(e) =>
                      setNewPerson({ ...NewPerson, Phone: e.target.value })
                    }
                    placeholder="Phone"
                    className="col-span-1 text-gray-900 bg-white rounded-md border border-gray-300 px-4 py-2"
                  />
                  {/* Email Input */}
                  <input
                    type="email"
                    value={NewPerson.Email}
                    onChange={(e) =>
                      setNewPerson({ ...NewPerson, Email: e.target.value })
                    }
                    placeholder="Email"
                    className="sm:col-span-2  col-span-1 text-gray-900 bg-white rounded-md border border-gray-300 px-4 py-2"
                  />
                </div>
              </div>

              {/* Footer */}
              <div className="flex justify-end items-center p-4 bg-white-100 border-t rounded-b-lg">
                {/* Add Owner Button */}
                <button
                  onClick={() => HandelAddOwner(index)}
                  className="bg-green-800 text-white px-4 py-2 rounded hover:bg-green-900 transition mx-2"
                > 

                  <span className="flex items-center gap-2">
                    {/* <BsPlus size={"20"} /> */}
                    Add
                  </span>
                </button>
                {/* Cancel Button */}
                <button
                  onClick={HandelCloseOwnerModal}
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-800 transition"
                >
                  <span className="flex items-center gap-2">
                    {/* <MdClose size={"20"} /> */}
                    Close
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
