import { useEffect, useState } from "react";
import { GetServicesInfo, GetServicesCards } from "../data/ServiceController";

export const UseServicesInfo = () => {
  const [ServicesInfo, setServicesInfo] = useState([]);
  useEffect(() => {
    const func = async () => {
      try {
        const GetData = await GetServicesInfo();
        setServicesInfo(GetData);
      } catch (error) {
        console.log(error);
      }
    };

    func();
  }, []);
  return ServicesInfo;
};

export const UseServicesCards = () => {
  const [ServicesCards, setServicesCards] = useState([]);
  useEffect(() => {
    const func = async () => {
      try {
        const GetData = await GetServicesCards();
        setServicesCards(GetData);
      } catch (error) {
        console.log(error);
      }
    };
    func();
  }, []);  
  return ServicesCards;
};
