import { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdAdd, MdClose } from "react-icons/md";
import { uploadUrl } from "../../../Components/urls";

export const RelatedModal = ({
  setNewService,
  setsetNewService,
  HandelRelatedAddService,
  showRelatedModal,
  HandelCloseRelatedModal,
  index,
  Invoices,
}) => {
  const handleOverlayClick = (e) => {
    if (e.target.id === "modal-overlay") {
      HandelCloseRelatedModal();
    }
  };

  return (
    <>
      {showRelatedModal && (
        <>
          {/* Modal Overlay */}
          <div
            id="modal-overlay"
            className="fixed inset-0 z-[11] flex items-center justify-center bg-black bg-opacity-90 "
            onClick={handleOverlayClick}
          >
            {/* Modal Content */}
            <div className="w-[800px] bg-white rounded-lg shadow-lg z-[999999]">
              {/* Header */}
              <div className="bg-white-100 p-4 rounded-t-lg border-b">
                <h2 className="text-xl font-semibold text-gray-700">
                  Add Related Service to order {index+1}
                </h2>
              </div>

              {/* Body */}
              {/* <div className="p-6"> */}
              <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 ">
                <div className="flex flex-col justify-center items-center col-span-1    rounded-lg ">
                  <div className="w-[100%] font-bold text-[22px] flex-left p-2 ">
                    Login to add your owen
                  </div>

                  <div className="p-2 w-[100%]">
                    <input
                      type="text"
                      value={setNewService.LastName}
                      onChange={(e) =>
                        setsetNewService({
                          ...setNewService,
                          LastName: e.target.value,
                        })
                      }
                      placeholder="UserName"
                      className=" text-gray-900 bg-white rounded-md border border-gray-300 p-2 w-[100%] "
                    />
                  </div>
                  <div className="p-2 w-[100%]">
                    <input
                      type="password"
                      value={setNewService.LastName}
                      onChange={(e) =>
                        setsetNewService({
                          ...setNewService,
                          LastName: e.target.value,
                        })
                      }
                      placeholder="Password"
                      className=" text-gray-900 bg-white rounded-md border border-gray-300 p-2 w-[100%]"
                    />
                  </div>
                  <div className="p-2 w-[100%]">
                    <button
                      onClick={() => HandelAddOwner(index)}
                      className="bg-green-800 text-white   p-2 rounded hover:bg-green-900 transition   w-[100%] "
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center col-span-1    rounded-lg ">
                  <div className="w-[100%] font-bold text-[22px] flex-left p-2 ">
                    Or Adopt a new service
                  </div>
                  <div className="w-[100%] font-bold text-[16px] flex-left p-2 ">
                    {Invoices[index].RelatedFor.filter(
                      (rlt) => rlt.RelatedSubPrerequisite === 0
                    ).map((rlt, i) => ( 
                        <div className="p-2 " key={i}>
                          <div className="flex items-center gap-2">
                          <img
                            src={uploadUrl + rlt.RelatedSubServicePhoto}
                            alt="Sub Service"
                            style={{
                              maxHeight: "100px",
                              maxWidth:"100px",
                              backgroundColor: "black",
                            }}
                          />
                          {/* <MdAdd  className="header-btn"/> */}
                          {rlt.RelatedSubServiceTitle}
                          <button
                            onClick={() => HandelRelatedAddService(i)}
                            className="bg-gray-800 text-white   flex-bottom   rounded hover:bg-green-900 transition mx-2 p-2"
                          >
                           Adopt for  ${rlt.RelatedSubPrice} 
                          </button>
                          </div>
                          
                        </div> 
                    ))}
                  </div>
                </div>
              </div>
              {/* </div> */}

            


            </div>
          </div>
        </>
      )}
    </>
  );
};
