import { createBrowserRouter } from "react-router-dom";
import Main from "../Main/Main";
import Main2 from "../Main/Main2";
import Main3 from "../Main/Main3";
import Main4 from "../Main/Main4";
import Home1 from "../Pages/Home1/Home1";
import Home2 from "../Pages/Home2/Home2";
import Home3 from "../Pages/Home3/Home3";
import ErrorPage from "../Shared/ErrorPage/ErrorPage";
import Home4 from "../Pages/Home4/Home4";
import Home5 from "../Pages/Home5/Home5";
import AboutInner from "../Pages/InnerPage/AboutInner/AboutInner";
import ServiceInner from "../Pages/InnerPage/ServiceInner/ServiceInner";
import ServiceDetails from "../Pages/InnerPage/ServiceDetails/ServiceDetails";
import ContactInner from "../Pages/InnerPage/ContactInner/ContactInner";
import BlogList from "../Pages/InnerPage/BlogList/BlogList";
import BlogDetails from "../Pages/InnerPage/BlogDetails/BlogDetails";
import BlogGrid from "../Pages/InnerPage/BlogGrid/BlogGrid";
import ProjectInner from "../Pages/InnerPage/ProjectInner/ProjectInner";
import TeamInner from "../Pages/InnerPage/TeamInner/TeamInner";
import PricingInner from "../Pages/InnerPage/PricingInner/PricingInner";
import DonationInner from "../Pages/InnerPage/DonationInner/DonationInner";
import ServiceDetails2 from "../Pages/InnerPage/ServiceDetails/ServiceDetails2";
import ServiceDetails3 from "../Pages/InnerPage/ServiceDetails/ServiceDetails3";
import ServiceDetails4 from "../Pages/InnerPage/ServiceDetails/ServiceDetails4";
import ServiceDetails5 from "../Pages/InnerPage/ServiceDetails/ServiceDetails5";
import ServiceDetails6 from "../Pages/InnerPage/ServiceDetails/ServiceDetails6";
import AppointmentInner from "../Pages/InnerPage/Appointment/AppointmentInner";
import ProjectDetails from "../Pages/InnerPage/ProjectDetails/ProjectDetails";
import FaqInner from "../Pages/InnerPage/Faq/Accordion/FaqInner";
import Main5 from "../Main/Main5";
import TestimonialInner from "../Pages/InnerPage/TestimonialInner/TestimonialInner";
import MapComponent from "../map";  
import AutoScrollToTop from "../Shared/ScrollToTop/AutoScrollTop";

document.title = "Jouzour Loubnan";
 const fetchData = async () => {
  try { 

    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/svg+xml';
    link.rel = 'icon';
    link.href = '/images/logo.png'; 
    if (!document.querySelector("link[rel*='icon']")) {
      document.head.appendChild(link);
    }  
  } catch (error) {
    console.error("Error fetching Brands:", error);
  }
};
fetchData(); 

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <Main />,
    children: [
      {
        path: "/",
        element:(<><AutoScrollToTop /> <Home1 /></>),
      },
      {
        path: "/about",
        element:(<><AutoScrollToTop /> <AboutInner /></>), 
      },
      {
        path: "/service", 
        element:(<><AutoScrollToTop /> <ServiceInner /></>), 
      },
      {
        path: "/service_details", 
        element:(<><AutoScrollToTop /> <ServiceDetails /></>), 
      },
      {
        path: "/service_details2", 
        element:(<><AutoScrollToTop /> <ServiceDetails2 /></>), 
      },
      {
        path: "/service_details3", 
        element:(<><AutoScrollToTop /> <ServiceDetails3 /></>), 
      },
      {
        path: "/service_details4", 
        element:(<><AutoScrollToTop /> <ServiceDetails4 /></>), 
      },
      {
        path: "/service_details5", 
        element:(<><AutoScrollToTop /> <ServiceDetails5 /></>), 
      },
      {
        path: "/service_details6", 
        element:(<><AutoScrollToTop /> <ServiceDetails6 /></>), 
      },
      {
        path: "/project", 
        element:(<><AutoScrollToTop /> <ProjectInner /></>), 
      },
      {
        path: "/project_details", 
        element:(<><AutoScrollToTop /> <ProjectDetails /></>), 
      },
      {
        path: "/donation_inner", 
        element:(<><AutoScrollToTop /> <DonationInner /></>), 
      },
      {
        path: "/team_inner", 
        element:(<><AutoScrollToTop /> <TeamInner /></>), 
      },
      {
        path: "/pricing_inner", 
        element:(<><AutoScrollToTop /> <PricingInner /></>), 
      },
      {
        path: "/faq_inner", 
        element:(<><AutoScrollToTop /> <FaqInner /></>), 
      },
      {
        path: "/blog_grid", 
        element:(<><AutoScrollToTop /> <BlogGrid /></>), 
      },
      {
        path: "/blog_list", 
        element:(<><AutoScrollToTop /> <BlogList /></>), 
      },
      {
        path: "/blog_details", 
        element:(<><AutoScrollToTop /> <BlogDetails /></>), 
      },
      {
        path: "/appointment", 
        element:(<><AutoScrollToTop /> <AppointmentInner /></>), 
      },
      {
        path: "/testimonial", 
        element:(<><AutoScrollToTop /> <TestimonialInner /></>), 
      },
      {
        path: "/contact", 
        element:(<><AutoScrollToTop /> <ContactInner /></>), 
      },
      {
        path: "/MapComponent", 
        element:(<><AutoScrollToTop /> <MapComponent /></>), 
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <Main2 />,
    children: [
      {
        path: "/home2",
        element: <Home2 />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <Main3 />,
    children: [
      {
        path: "/home3",
        element: <Home3 />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <Main4 />,
    children: [
      {
        path: "/home4",
        element: <Home4 />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <Main5 />,
    children: [
      {
        path: "/home5",
        element: <Home5 />,
      },
    ],
  },
] ,
{
 
    future: { 
      v7_startTransition: true,               // State updates wrapped in `startTransition`
      v7_relativeSplatPath: true,             // Relative path resolution for splat routes
      v7_fetcherPersist: true,                // Changes in fetcher persistence behavior
      v7_normalizeFormMethod: true,           // Normalize `formMethod` fields to uppercase
      v7_partialHydration: true,              // Changes in RouterProvider hydration
      v7_skipActionErrorRevalidation: true,   // Changes in revalidation after 4xx/5xx responses
    },
  }
 );

export default router;
