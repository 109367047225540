import serviceShape from "/images/service-shape.png";
import { Link, useLocation } from "react-router-dom";
import BreadCrumb from "../../../Shared/BreadCrumb/BreadCrumb";
import wedgetIcon from "/images/widget-icon.png";
import projectDetialsBoxImg from "/images/services-details-benifis-thumb-1.png";
import projectDetialsBoxImg2 from "/images/services-details-benifis-thumb-2.png";
import {
  FaArrowLeft,
  FaArrowRight,
  FaArrowRightLong,
  FaRegFolderOpen,
} from "react-icons/fa6";
import {
  Md1kPlus,
  MdAdd,
  MdAddCard,
  MdClose,
  MdConfirmationNumber,
  MdContactMail,
  MdCurrencyExchange,
  MdDelete,
  MdDescription,
  MdEmail,
  MdNumbers,
  MdOutlineFormatListNumbered,
  MdOutlineProductionQuantityLimits,
  MdPerson,
  MdPhone,
  MdProductionQuantityLimits,
  MdRemove,
} from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import { BsFileEarmarkPdf, BsPlus } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import { UseServicesCards } from "../../../Components/useServices";
import { uploadUrl } from "../../../Components/urls";
import { useEffect, useMemo, useState } from "react";
import ServiceCard from "../../../Componant1/Service/ServiceCard";
import { GoArrowUpRight } from "react-icons/go";
import { OwnerModal } from "./OwnersModal";
import { RelatedModal } from "./RelatedModal";

const ServiceDetails = () => {
  const serviceCards = UseServicesCards();
  const SubService = serviceCards[0]?.SubService || [];
  const { state } = useLocation() || {};

  const card = useMemo(() => {
    if (state) {
      return [state];
    }
    if (SubService && SubService.length > 0) {
      return [SubService[0]];
    }
    return [];
  }, [state, SubService]);

  const SubPrerequisite = SubService.filter(
    (sr) => sr.SubPrerequisite && sr.OncePerOrder
  );
  const [Invoices, setInvoices] = useState(card);
  useEffect(() => {
    setInvoices(card);
  }, [card]);
 

  const finalInvoice = [...Invoices, ...SubPrerequisite]; 
  const subtotal = finalInvoice.reduce(
    (acc, invoice) => acc + invoice.TotalInvPrice,
    0
  );

  // const [SubSelected, setSubSelected] = useState([
  //   ...subService.filter((sb) => sb.SubNewPrerequisite === 1),
  // ]);

  // useEffect(() => {
  //   if (subService.length > 0) {
  //     setSubSelected([
  //       ...subService.filter((sb) => sb.SubNewPrerequisite === 1),
  //     ]);
  //   }
  // }, [subService]);

  const HandelAddInvoice = async (sub) => {
    // const prr = SubService.filter((sb) =>
    //   sub.RelatedFor.some((rf) => rf.SubServiceID === sb.SubServiceID)
    // );
    // const uniquePrr = prr.filter(
    //   (rl) =>
    //     !Invoices.some((invoice) => invoice.SubServiceID === rl.SubServiceID) &&
    //     rl.OncePerOrder
    // );
    setInvoices([...Invoices, sub]);
  };
  const HandelRemoveInvoice = async (index) => { 
    const updatedInvoices = Invoices.filter((_, i) => i !== index);
    setInvoices(updatedInvoices);
  };
  const [NewPerson, setNewPerson] = useState({
    Firstname: "",
    MiddleName: "",
    LastName: "",
    Email: "",
    Phone: "",
  });
  const [NewService, setNewService] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showRelatedModal, setShowRelatedModal] = useState(false);
  const [index, setIndex] = useState();

  const HandelShowOwnerModal = (i) => {
    setShowModal(true);
    setIndex(i);
  };

  const HandelCloseOwnerModal = () => {
    setShowModal(false);
  };

  const HandelShowRelatedModal = (i) => {
    setShowRelatedModal(true);
    setIndex(i);
  };
  const HandelCloseRelatedModal = () => {
    setShowRelatedModal(false);
  };

  const HandelRelatedAddService = async (i) => {
    // Ensure RelatedFor[i] is valid and push it to relatedFor array if necessary
    const relatedFor = Invoices[index]?.RelatedFor?.[i]
      ? Invoices[index].RelatedFor[i]
      : [];

    const updatedInvoice = {
      ...Invoices[index],
      NewRelatedService: relatedFor,
      TotalInvPrice: Invoices[index].NewSubService
        ? relatedFor.RelatedSubPrice +
          Invoices[index].SubPrice +
          Invoices[index].NewSubService.reduce(
            (acc, nsb) => acc + (nsb.SubPrice || 0),
            0
          )
        : relatedFor.RelatedSubPrice + Invoices[index].SubPrice,

      // TotalInvPrice: relatedFor.RelatedSubPrice + Invoices[index].TotalInvPrice
    };

    const updatedInvoices = [...Invoices];
    updatedInvoices[index] = updatedInvoice;
    setInvoices(updatedInvoices);
    setShowRelatedModal(false);
  };

  const HandelRemoveService = async (sub,i) => { 
    console.log(sub)
    console.log(i)
    
    const updatedInvoice = {
      ...Invoices[i],  
      NewSubService: 
      Invoices[i].NewSubService.filter((nsv)=> nsv.SubServiceID !==  sub.SubServiceID )
        };

    // Update the invoices array
    const updatedInvoices = [...Invoices];
    updatedInvoices[i] = updatedInvoice; // Update the specific invoice at index `i`

    // Set the updated invoices array
    setInvoices(updatedInvoices);

     
  }
  const HandelSubAddService = async (sub, i, e) => { 
   console.log(sub.Quantity)
    const updatedInvoice = {
      ...Invoices[i],  
      NewSubService: Invoices[i].NewSubService
      ? Invoices[i].NewSubService.map(service => 
          service.SubServiceID === sub.SubServiceID 
            ? { ...service, Quantity: service.Quantity + (e ? 1 : service.Quantity > 1 ? -1 : false) } 
            : service
        ).concat(
          !Invoices[i].NewSubService.some(service => service.SubServiceID === sub.SubServiceID) 
            ? { ...sub, Quantity: (e ? 1 : -1) } 
            : []
        )
        : [{sub, ...sub, Quantity:1}],

      TotalInvPrice: Invoices[i].NewRelatedService
        ? (Invoices[i].NewSubService
            ? Invoices[i].NewSubService.reduce(
                (acc, nsb) => acc + (nsb.SubPrice || 0),
                0
              ) + sub.SubPrice
            : sub.SubPrice) +
          Invoices[i].SubPrice +
          Invoices[i].NewRelatedService.RelatedSubPrice
        : (Invoices[i].NewSubService
            ? 
           (!e && sub.Quantity && sub.Quantity < 1) ?
               false 
          :
              Invoices[i].NewSubService.reduce(
                  (acc, nsb) => acc + ((nsb.SubPrice * nsb.Quantity) || 0),
                  0
              ) + (e ? sub.SubPrice : -sub.SubPrice)
          
            : 
            sub.SubPrice) 
            
            + Invoices[i].SubPrice,
    };

    // Update the invoices array
    const updatedInvoices = [...Invoices];
    updatedInvoices[i] = updatedInvoice; // Update the specific invoice at index `i`

    // Set the updated invoices array
    setInvoices(updatedInvoices);
  };  
  const HandelAddOwner = async (index) => {
    const updatedInvoice = {
      ...Invoices[index],
      persons: [...(Invoices[index].persons || []), NewPerson],
    };
    const updatedInvoices = [...Invoices];
    updatedInvoices[index] = updatedInvoice;
    setInvoices(updatedInvoices);
    setNewPerson({
      Firstname: "",
      MiddleName: "",
      LastName: "",
      Email: "",
      Phone: "",
    });
  };

  const HandelDeleteOwner = async (invoiceIndex, personIndex) => {
    const updatedInvoice = {
      ...Invoices[invoiceIndex],
      persons: Invoices[invoiceIndex].persons.filter(
        (_, index) => index !== personIndex
      ),
    };
    const updatedInvoices = [...Invoices];
    updatedInvoices[invoiceIndex] = updatedInvoice;
    setInvoices(updatedInvoices);
  };

  // const TotalPrice = () => {
  //   const total = SubSelected
  //     ? SubSelected.reduce((acc, srv) => acc + (srv.SubIsNewPrice || 0), 0)
  //     : 0;
  //   return total + Number(card.ServicePrice);
  // };

  const HandelSelectSub = async (i) => {};

  if (!card) return null;
  return (
    <>
      <OwnerModal
        NewPerson={NewPerson}
        setNewPerson={setNewPerson}
        HandelAddOwner={HandelAddOwner}
        index={index}
        HandelCloseOwnerModal={HandelCloseOwnerModal}
        showModal={showModal}
      />
      <RelatedModal
        NewService={NewService}
        setNewService={setNewService}
        HandelRelatedAddService={HandelRelatedAddService}
        index={index}
        HandelCloseRelatedModal={HandelCloseRelatedModal}
        showRelatedModal={showRelatedModal}
        Invoices={Invoices}
      />
      <BreadCrumb
        breadCrumbPhoto={
          uploadUrl + "/" + "upload/ServicesPhoto/0uoI4Uqfgy_20240926204453.jpg"
        }
        breadCrumbTitle={card.ServiceCardTitle}
        breadCrumbText={card.ServiceCardText}
        breadCrumbIcon={<FaArrowRightLong />}
        breadCrumbLink={"Serivces"}
        breadCrumbIcon2={<FaArrowRightLong />}
        breadCrumbContent={card.ServiceCardTitle}
      />
      <section className="py-[20px] bg-white">
        <div className="Container">
          <div className="grid grid-cols-3 gap-[70px]">
            <div className="col-span-3 lg:col-span-2">
              {SubPrerequisite &&
                SubPrerequisite.map((rlt, i) => (
                  <div
                    className="grid sm:grid-cols-3 lg:grid-cols-3 gap-[11px] p-7 rounded border"
                    key={i}
                  >
                    {/* Image Section */}
                    <div className="col-span-1 lg:col-span-1">
                      <img
                        src={uploadUrl + rlt.SubServicePhoto}
                        alt="Sub Service"
                        style={{ maxHeight: "100px", backgroundColor: "black" }}
                      />
                    </div>
                    <div className="col-span-2 lg:col-span-2">
                      <h1 className="text-[20px] font-bold">
                        {rlt.SubServiceTitle}
                      </h1>
                      <h1 className="text-[12px] font-bold">
                        {rlt.SubServiceTitle}
                      </h1>
                    </div>

                    <div className="col-span-3 lg:col-span-3 flex items-end justify-end  ">
                      ${rlt.SubPrice}
                    </div>
                  </div>
                ))}
              {Invoices &&
                Invoices.filter((rtl) => rtl.OncePerOrder === 0).map(
                  (inv, i) => (
                    <div
                      className="grid sm:grid-cols-3 lg:grid-cols-3 gap-[11px] p-7 rounded border mt-2"
                      key={i}
                    >
                      <div className="col-span-3 lg:col-span-3">
                        Order {i + 1}
                      </div>
                      <div className="col-span-1 lg:col-span-1">
                        <img
                          src={uploadUrl + inv.SubServicePhoto}
                          alt="Sub Service"
                          style={{ maxHeight: "100px" }}
                        />
                        {inv && inv.persons && inv.persons.length > 0 && (
                          <div className="bg-[#f3f4f8] border rounded p-2">
                            Owner{inv.persons.length > 1 && "s"}
                            {inv.persons &&
                              inv.persons.map((pr, pi) => (
                                <div
                                  key={pi}
                                  className="font-AlbertSans text-[#000000]
                                     grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 lg:gap-2 md:gap-2
                                        mt-1 mb-[12px]
                                    // bg-[#f3f4f8]
                                    bg-[#fff]
                                    rounded
                                    row
                                    p-2
                                     text-[12px]
                                    "
                                >
                                  <span className="font-AlbertSans flex gap-0 my-[2px]">
                                    <MdPerson className="text-xl text-PrimaryColor-0 " />
                                    {pr.Firstname} {pr.MiddleName} {pr.LastName}
                                  </span>
                                  <span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
                                    <MdEmail className="text-xl text-PrimaryColor-0 " />
                                    {pr.Email}
                                  </span>
                                  <span className=" font-AlbertSans flex gap-0 my-[2px] ">
                                    <MdPhone className="text-xl text-PrimaryColor-0 " />{" "}
                                    {pr.Phone}
                                    <span
                                      onClick={() => HandelDeleteOwner(i, pi)}
                                    >
                                      <MdDelete className="text-xl text-[#f00] hover:text-[#ccc]  mx-6" style={{cursor:'pointer'}}/>
                                    </span>
                                  </span>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                      <div className="col-span-1 lg:col-span-2">
                        <h1 className="text-[20px] font-bold">
                          {inv.SubServiceTitle}
                          <span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
                            <MdCurrencyExchange className="text-xl text-PrimaryColor-0 mr-2" />
                            {inv.SubPrice}
                          </span>
                        </h1>
                        <h1 className="text-[16px] font-bold">
                          {inv.SubServiceDesc}
                        </h1>
                        {inv.HaveContact || inv.RelatedToService ? (
                          <div className=" font-bold text-[#f00] mt-2">
                            Required:
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex items-center justify-left gap-[24px] text-[12px] my-2">
                          {inv?.RelatedToService || inv.HaveContact ? (
                            <div className="border rounded-md flex gap-[24px] p-2 bg-[#f3f4f8]">
                              {inv.HaveContact ? (
                                <>
                                  <div
                                    onClick={() => HandelShowOwnerModal(i)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div>
                                      <MdContactMail className="text-[#000] text-[33px]" />{" "}
                                    </div>
                                    <div>Add Owner</div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {inv?.RelatedToService ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => HandelShowRelatedModal(i)}
                                >
                                  <div>
                                    <MdAddCard className="text-[#000] text-[33px]" />{" "}
                                  </div>
                                  <div>
                                    {" "}
                                    {inv.NewRelatedService ? "Edit " : "Add "}
                                    related service
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="border rounded-md flex gap-[24px] p-2 bg-[#f3f4f8]">
                            {SubService &&
                              SubService.filter(
                                (sub) =>
                                  !sub.prerequisites &&
                                  sub.SubServiceID !== inv.SubServiceID
                              ).map((sub, ii) => (
                                <div
                                  key={ii}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => HandelSubAddService(sub, i, true)}
                                >
                                  <div>
                                    <img
                                      src={uploadUrl + sub.SubServicePhoto}
                                      draggable="false"
                                      className="transition-all duration-500 group-hover:scale-110 w-[33px]"
                                    />
                                  </div>
                                  <div>Add {sub.SubServiceTitle}</div> {i}
                                </div>
                              ))}
                          </div>
                        </div>
                        
                            <div className="bg-[#f3f4f8] border rounded p-2">
                              Service
                              {inv && inv.NewRelatedService && (
                                <div
                                  className="font-AlbertSans text-[#000000]
                                     grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:gap-2 md:gap-2
                                        mt-1 mb-[12px]
                                    // bg-[#f3f4f8]
                                    bg-[#fff]
                                    rounded
                                    row
                                    p-2
                                     text-[12px]
                                    "
                                >
                                  <div className="overflow-hidden rounded">
                                    <img
                                      src={
                                        uploadUrl +
                                        inv.NewRelatedService
                                          .RelatedSubServicePhoto
                                      }
                                      draggable="false"
                                      className="transition-all duration-500 group-hover:scale-110"
                                    />
                                  </div>
                                  <div className="overflow-hidden rounded">
                                    <span className="font-AlbertSans flex gap-0 my-[2px]">
                                      <MdDescription className="text-xl text-PrimaryColor-0 mr-2 " />
                                      {
                                        inv.NewRelatedService
                                          .RelatedSubServiceTitle
                                      }
                                    </span>
                                    <span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
                                      <MdCurrencyExchange className="text-xl text-PrimaryColor-0 mr-2" />
                                      {inv.NewRelatedService.RelatedSubPrice}  
                                    </span> 
                                  </div>
                                </div>
                              )}
                              {inv.NewSubService &&
                                inv.NewSubService.map((nsv, iii) => (
                                  <div
                                    key={iii}
                                    className="font-AlbertSans text-[#000000]
                                     grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 lg:gap-1 md:gap-2
                                        mt-1 mb-[12px]
                                    // bg-[#f3f4f8]
                                    bg-[#fff]
                                    rounded
                                    row
                                    p-2
                                     text-[12px]
                                    "
                                  >
                                    <div className="overflow-hidden   rounded">
                                      <img
                                        src={uploadUrl + nsv.SubServicePhoto}
                                        draggable="false"
                                        className="transition-all duration-500 group-hover:scale-110 w-[33px]"
                                      /> 
                                      </div> 
                                          
                                    <div className="flex-3">
                                      Quantity
                                      <span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
                                        {/* <MdOutlineProductionQuantityLimits className="text-xl text-PrimaryColor-0 mr-2" /> */}
                                         <MdRemove  className="text-xl text-[#f00] mr-2" onClick={() => HandelSubAddService(nsv, i, false)} style={{cursor:'pointer'}} />
                                         <input type="text" value={nsv.Quantity} className=" text-PrimaryColor-0 text-[18px] border w-[54px] h-[24px] text-center p-2" />
                                         <MdAdd  className="text-xl text-PrimaryColor-0 mr-2"  onClick={() => HandelSubAddService(nsv, i, true)} style={{cursor:'pointer'}}/>
                                      </span>
                                      
                                    </div>
                                    <div className="overflow-hidden rounded">
                                      <span className="font-AlbertSans flex gap-0 my-[2px]">
                                        <MdDescription className="text-xl text-PrimaryColor-0 mr-2 " />
                                        {nsv.SubServiceTitle}
                                      </span>
                                      <span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
                                        <MdCurrencyExchange className="text-xl text-PrimaryColor-0 mr-2" />
                                        {nsv.SubPrice * nsv.Quantity}
                                      </span>                                     
                                  
                                      <span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]" style={{cursor:'pointer'}} onClick={()=>HandelRemoveService(nsv,i)}>
                                        <MdClose className="text-xl text-[#f00] mr-2" /> Remove
                                      </span> 
                                    </div>
                                  </div>
                                ))}
                            </div>
                          {/* )} */}
                        {inv && inv.NewService && inv.NewService.length > 0 && (
                          <div className="">
                            Service{inv.NewService.length > 1 && "s"}
                            {inv.NewService &&
                              inv.NewService.map((rltsrv, i) => (
                                <div
                                  key={i}
                                  className="font-AlbertSans text-[#000000]
                                     grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:gap-2 md:gap-2
                                        mt-1 mb-[12px]
                                    // bg-[#f3f4f8]
                                    bg-[#fff]
                                    rounded
                                    row
                                    p-2
                                     text-[12px]
                                    "
                                >
                                  <div className="overflow-hidden rounded">
                                    <img
                                      src={
                                        uploadUrl +
                                        rltsrv.RelatedSubServicePhoto
                                      }
                                      draggable="false"
                                      className="transition-all duration-500 group-hover:scale-110"
                                    />
                                  </div>
                                  <div className="overflow-hidden rounded">
                                    <span className="font-AlbertSans flex gap-0 my-[2px]">
                                      <MdDescription className="text-xl text-PrimaryColor-0 mr-2 " />
                                      {rltsrv.RelatedSubServiceTitle}
                                    </span>
                                    <span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
                                      <MdCurrencyExchange className="text-xl text-PrimaryColor-0 mr-2" />
                                      {rltsrv.RelatedSubPrice}
                                    </span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                        {/* </div> */}
                      </div>

                      <div className="col-span-3 lg:col-span-3 flex items-end justify-end  ">
                        ${inv.TotalInvPrice ? inv.TotalInvPrice : inv.SubPrice}
                        {Invoices.length > 1 && (
                          <MdDelete
                            onClick={() => HandelRemoveInvoice(i)}
                            className="text-[#000] text-[24px]"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </div>
                  )
                )}

              {/* <h2 className="font-AlbertSans font-bold text-2xl sm:text-3xl text-HeadingColor-0 mt-[54px]">
                What the Benifits?
              </h2>
              <p className="font-AlbertSans text-TextColor-0 mt-6">
                Alternative innovation to ethical network environmental
                whiteboard pursue compelling results for premier methods
                empowerment. Dramatically architect go forward opportunities
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mt-9">
                <div className="text-center group">
                  <div className="overflow-hidden rounded">
                    <img
                      src={projectDetialsBoxImg}
                      draggable="false"
                      className="transition-all duration-500 group-hover:scale-110"
                    />
                  </div>
                  <div className="bg-white shadow-cases rounded inline-block pt-7 pl-4 sm:pl-8 pb-7 w-4/5 -mt-5 relative z-10">
                    <h4 className="font-AlbertSans text-HeadingColor-0 font-semibold text-[22px] lg:text-xl xl:text-[22px] text-left mb-3">
                      Green Environment
                    </h4>
                    <ul>
                      <li className="flex items-center gap-2 text-TextColor-0 text-left font-AlbertSans mb-2 lg:text-sm xl:text-base">
                        <IoMdCheckmark className="text-PrimaryColor-0 text-xl" />
                        New Modern Equipments
                      </li>
                      <li className="flex items-center gap-2 text-TextColor-0 text-left font-AlbertSans lg:text-sm xl:text-base">
                        <IoMdCheckmark className="text-PrimaryColor-0 text-xl" />
                        Expert’s Volunteers
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-center group">
                  <div className="overflow-hidden rounded">
                    <img
                      src={projectDetialsBoxImg2}
                      draggable="false"
                      className="transition-all duration-500 group-hover:scale-110"
                    />
                  </div>
                  <div className="bg-white shadow-cases rounded inline-block pt-7 pl-4 sm:pl-8 pb-7 w-4/5 -mt-5 relative z-10">
                    <h4 className="font-AlbertSans text-HeadingColor-0 font-semibold text-[22px] lg:text-xl xl:text-[22px] text-left mb-3">
                      Renewable Energy
                    </h4>
                    <ul>
                      <li className="flex items-center gap-2 text-TextColor-0 text-left font-AlbertSans mb-2 lg:text-sm xl:text-base">
                        <IoMdCheckmark className="text-PrimaryColor-0 text-xl" />
                        New Modern Equipments
                      </li>
                      <li className="flex items-center gap-2 text-TextColor-0 text-left font-AlbertSans lg:text-sm xl:text-base">
                        <IoMdCheckmark className="text-PrimaryColor-0 text-xl" />
                        Expert’s Volunteers
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-span-3 lg:col-span-1">
              <div className="  border rounded-md px-4 sm:px-8 lg:px-6 xl:px-8 pt-7 pb-4 mb-7">
                <h4 className="font-AlbertSans font-bold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-PrimaryColor-0">
                  Order Summary
                  <div className="text-[12px]"> {Invoices.length} item </div>
                </h4>

                <h4 className="font-AlbertSans font-semibold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0  before:h-[2px] before:bg-PrimaryColor-0">
                  Subtotal<span className="text-[12px]">(USD)</span>{" "}
                  <span className="mx-6">${subtotal} </span>
                </h4>

                <div
                  className=" pr-8  border-[#94c933] p-2 text-left"
                  style={{ fontSize: "16px", fontWeight: "bolder" }}
                >
                  <Link to={"/"}>
                    <button className="font-AlbertSans flex gap-2 items-center bg-[#94c933] p-6 w-full h-[28px] rounded-md justify-center z-10 relative before:absolute before:top-0 before:right-0 before:scale-0 before:-z-10 before:w-full before:h-full before:bg-SecondaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:scale-100 hover:text-white">
                      Ready to Pay
                      <FaArrowRight />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="bg-[#f3f4f8]  border rounded-md px-4 sm:px-8 lg:px-6 xl:px-8 pt-7 pb-4 mb-7">
                <h4 className="font-AlbertSans font-bold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0 before:w-7 before:h-[2px] before:bg-PrimaryColor-0">
                  Add Service
                </h4>

                <ul className="mt-8">
                  {SubService &&
                    SubService.filter((sub) => !sub.OncePerOrder).map(
                      (sub, i) => (
                        <li key={i}>
                          <button
                            onClick={() => HandelAddInvoice(sub)}
                            className="w-full font-AlbertSans bg-SecondaryColor-0 text-center text-white transition-all duration-500 group gap-2 px-1 py-4 flex items-center justify-left rounded bg-HoverColor-0 mb-3 overflow-hidden z-[1] relative before:absolute before:top-0 before:right-0 before:w-0 before:-z-[1] before:h-full before:bg-PrimaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:text-white"
                          >
                            {" "}
                            <FaArrowLeft
                              size={"20"}
                              className="text-PrimaryColor-0 transition-all duration-500 group-hover:text-white"
                            />{" "}
                            {sub.SubServiceTitle}{" "}
                          </button>
                        </li>
                      )
                    )}
                </ul>
              </div>
              {/* <div className="bg-[#f3f4f8] rounded-md px-4 sm:px-8 lg:px-6 xl:px-8 pt-7 pb-4 mb-7">
                <h4 className="font-AlbertSans font-semibold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0 before:w-7 before:h-[2px] before:bg-PrimaryColor-0">
                  Downloads
                </h4>
                <ul className="mt-8">
                  <li>
                    <Link to={"/"}>
                      <button className="w-full font-AlbertSans bg-SecondaryColor-0 text-left text-white transition-all duration-500 group px-7 py-4 flex items-center justify-between rounded bg-HoverColor-0 mb-3 overflow-hidden z-[1] relative before:absolute before:top-0 before:right-0 before:w-0 before:-z-[1] before:h-full before:bg-PrimaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:text-white">
                        <span className="flex items-center gap-3">
                          <BsFileEarmarkPdf
                            size={"20"}
                            className="text-PrimaryColor-0 transition-all duration-500 group-hover:text-white"
                          />
                          Service Report
                        </span>
                        <HiDownload size={"24"} className="text-white" />
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <button className="w-full font-AlbertSans bg-SecondaryColor-0 text-left text-white transition-all duration-500 group px-7 py-4 flex items-center justify-between rounded bg-HoverColor-0 mb-3 overflow-hidden z-[1] relative before:absolute before:top-0 before:right-0 before:w-0 before:-z-[1] before:h-full before:bg-PrimaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:text-white">
                        <span className="flex items-center gap-3">
                          <BsFileEarmarkPdf
                            size={"20"}
                            className="text-PrimaryColor-0 transition-all duration-500 group-hover:text-white"
                          />
                          Service List
                        </span>
                        <HiDownload size={"24"} className="text-white" />
                      </button>
                    </Link>
                  </li>
                </ul>
              </div> */}
              {/* <div className="rounded-lg px-9 overflow-hidden bg-[url('/images/sidebar-bg.jpg')] bg-cover bg-no-repeat bg-center py-[50px]">
                <img src={wedgetIcon} draggable="false" />
                <h6 className="font-AlbertSans font-medium text-lg text-white mt-5 mb-2">
                  Call Us Anytime
                </h6>
                <Link to={"/"}>
                  <button className="font-AlbertSans font-semibold text-2xl text-white">
                    +123 (4567) 890
                  </button>
                </Link>
                <Link to={"/"}>
                  <button className="font-AlbertSans text-white flex gap-2 items-center mt-4 mb-[52px]">
                    <MdEmail className="text-xl text-PrimaryColor-0" />
                    example@gmail.com
                  </button>
                </Link>
                <Link to={"/"}>
                  <button className="font-AlbertSans text-white flex gap-2 items-center bg-PrimaryColor-0 w-full h-[58px] rounded-md justify-center z-10 relative before:absolute before:top-0 before:right-0 before:scale-0 before:-z-10 before:w-full before:h-full before:bg-SecondaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:scale-100 hover:text-white">
                    Contact Us
                    <FaArrowRight />
                  </button>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetails;
